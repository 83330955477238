import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

export const AuthGuard = ({ children }: { children: React.ReactNode }) => {
    return <>
        <AuthenticatedTemplate>
            {children}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            Please log in before you proceed.
        </UnauthenticatedTemplate>
    </>
};