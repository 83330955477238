import { DateBox } from "devextreme-react"
import React from "react"
import { Form } from "react-bootstrap"

type FormLabelProps = {
    id: string,
    label: string,
    value?: string,
}

export const FormLabel = ({ id, label, value }: FormLabelProps) =>
    <div className="col-md-4">
        <label htmlFor={id} className="form-label">{label}:</label><br />
        <span id={id}>{value}</span>
    </div>



type FormInputProps = {
    id: string,
    label: string,
    onValueChanged?: (e: React.FocusEvent<HTMLInputElement>) => void,
    value?: string,
    required: boolean,
    type?: string,
    defaultValue?: string,
    disabled?: boolean
}

export const FormInput = ({ id, label, required, type, defaultValue, disabled, onValueChanged, value }: FormInputProps) =>
    <div className="col-md-3">
        <label htmlFor={id} className="form-label">{label}</label>
        <Form.Control id={id} type={type ?? "text"} className="form-control" required={required} disabled={disabled} onChange={onValueChanged} defaultValue={defaultValue} isValid={value !== undefined && value !== ""} />
    </div>

type FormDropdownProps = {
    id: string,
    label: string,
    values: string[],
    displayValues?: string[],
    divClassName?: string,
    onValueChanged?: (e: React.FocusEvent<HTMLSelectElement, Element>) => void,
    value?: string,
    required?: boolean,
    validate?: boolean
}

export const FormDropdown = ({ id, label, values, onValueChanged, value, required, displayValues, divClassName, validate }: FormDropdownProps) =>
    <div className={divClassName ? divClassName : "col-md-3"}>
        <label htmlFor={id} className="form-label">{label}</label>
        <Form.Select id={id} className="form-control form-select" required={required} onChange={onValueChanged} value={value} defaultValue={""} isValid={validate && value !== undefined && value !== ""}>
            <option key={-1} disabled value={""}>Επιλογή...</option>
            {values?.map((v, i) => <option key={i} value={v} >{displayValues ? displayValues[i] : v}</option>)}
        </Form.Select>
    </div>

type FormDateProps = {
    id: string,
    label: string,
    onValueChange: (e: Date) => void
}

export const FormDate = ({ id, label, onValueChange }: FormDateProps) =>
    <div className="col-md-3">
        <label htmlFor={id} className="form-label">{label}</label>
        <DateBox id={id} className="form-date form-control" type="date" placeholder={new Date().toLocaleDateString('el')} useMaskBehavior={true} pickerType="calendar" onValueChange={onValueChange}></DateBox>
    </div>