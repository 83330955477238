import { useMsal } from "@azure/msal-react";
import { Card, Container } from "react-bootstrap"


export const LoggedIn = () => {
    const { instance } = useMsal();
    const user = instance.getActiveAccount()?.name;

    return (
        <>
            <Container className="my-5 " style={{ width: '30%', marginLeft: '35%', marginRight: '35%' }}>
                <Card className="text-center">
                    <Card.Header className=" fs-5">Welcome, {user?.split(" ")[0]}!</Card.Header>
                    <Card.Body className="mt-3 fs-6"><p>Navigate through the app using the navigation bar in the top left section of the screen</p></Card.Body>
                    <Card.Footer>
                    </Card.Footer>
                </Card>
            </Container>
        </>
    )
} 