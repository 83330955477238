import { DataGrid, Toast } from "devextreme-react";
import { Button, Form } from "react-bootstrap";
import { Column } from "devextreme-react/data-grid";
import '../App.css';
import { useEffect, useRef, useState } from "react";
import * as types from "../lib/types";
import { Link } from "react-router-dom";
import { FormInput, FormDropdown, FormDate } from "../components/Form";
import { useAuth } from "../hooks/useAuth";
import { loginRequest } from "../lib/msal-config";
import { ToastType } from "devextreme/ui/toast";
import { getIssueListDataStore } from "../lib/utils";
import CustomStore from "devextreme/data/custom_store";
import { AuthGuard } from "../components/AuthGuard";

export const IssueList = () => {
    const defaultFilter: types.FilterFields = { status: 'To Do' };
    const auth = useAuth(loginRequest);
    const [filters, setFilters] = useState<types.FilterFields>(defaultFilter);
    const [tempFilters, setTempFilters] = useState<types.FilterFields>(defaultFilter);
    const [toastConfig, setToastConfig] = useState({ isVisible: false, textMessage: "", type: "info" as ToastType });
    const [dataStore, setDataStore] = useState<CustomStore>()
    const dataGrid = useRef<DataGrid<any, any>>(null);
    useEffect(() => { setDataStore(getIssueListDataStore(filters, setToastConfig, auth)) }, [filters, auth])

    return (<AuthGuard>
        <Toast visible={toastConfig.isVisible} message={toastConfig.textMessage} type={toastConfig.type} onHiding={() => setToastConfig({ ...toastConfig, isVisible: false })}></Toast>
        <Form id="filterForm" onSubmit={s => { setFilters(tempFilters); s.preventDefault(); s.stopPropagation(); }} onReset={r => { setFilters(defaultFilter); setTempFilters(defaultFilter); }} >
            <div className="row g-3" style={{ margin: 30 }}><div className="fs-5">Φίλτρα</div>
                <FormInput id="refNo" label="Card Reference No" required={false} onValueChanged={(e) => setTempFilters({ ...tempFilters, refNo: e.target.value })} />
                <FormDropdown id="reqCateg" label="Request Category" values={["Embossing Καρτών", "Embossing PIN"]} required={false} onValueChanged={(e) => setTempFilters({ ...tempFilters, reqCateg: e.target.value })} />
                <FormDropdown id="embMenu" label="Embossing Menu" values={["ACBDEB", "ACBDYN", "ACBST", "AMEX", "CRED", "CREDC", "DINERS"]} required={false} onValueChanged={(e) => setTempFilters({ ...tempFilters, embMenu: e.target.value })} />
                <FormInput id="key" label="Key" required={false} onValueChanged={(e) => setTempFilters({ ...tempFilters, key: e.target.value })} />
                <FormDropdown id="status" label="Status" required={false} values={["To Do", "PRINT - In Progress", "PRINT - DONE", "MAIL - In Progress", "DONE"]} value={tempFilters.status} onValueChanged={(e) => setTempFilters({ ...tempFilters, status: e.target.value })} />
                <FormDate id="createdBefore" label="Δημιουργήθηκε Πριν" onValueChange={(e) => { setTempFilters({ ...tempFilters, createdBefore: e }) }} />
                <FormDate id="createdAfter" label="Δημιουργήθηκε Μετά" onValueChange={(e) => { setTempFilters({ ...tempFilters, createdAfter: e }) }} />

            </div>
            <Button type="submit" style={{ marginLeft: 30 }}>Αναζήτηση</Button>
            <Button type="reset" style={{ marginLeft: 10 }}>Καθαρισμός</Button>
        </Form>
        <div style={{ marginTop: 10 }}>
            <label htmlFor="listGrid" className="form-label"></label>
            <>
                <DataGrid ref={dataGrid} id="listGrid" dataSource={dataStore} hoverStateEnabled showBorders={true} className="mx-4" >
                    <Column caption="Key" dataField="key" cellRender={(e) => (<Link to={`/issue/${e.data.key}`}>{e.data.key}</Link>)} dataType="string" width={100}></Column>
                    <Column caption="Reporter" dataField="reporter" dataType="string" ></Column>
                    <Column caption="Summary" dataField="summary" dataType="string" ></Column>
                    <Column caption="Department" dataField="label" dataType="string" ></Column>
                    <Column caption="Status" dataField="status" dataType="string" ></Column>
                    <Column caption="Assignee" dataField="assignee" dataType="string"></Column>
                    <Column caption="Due Date" dataField="dueDate" dataType="date" ></Column>
                    <Column caption="Created" dataField="created" dataType="date" ></Column>
                    <Column caption="Updated" dataField="updated" dataType="date" ></Column>
                </DataGrid>
            </>
        </div>
    </AuthGuard>
    )
}