import './App.css';
import { IssueCreation } from './pages/IssueCreation';
import { Issue } from './pages/Issue';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { IssueList } from './pages/IssueList';
import { Navbar } from './components/Navbar';
import * as types from '../src/lib/types'
import { IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { WelcomeScreen } from './pages/WelcomeScreen';
import { LoggedIn } from './pages/LoggedIn';
import elMessages from 'devextreme/localization/messages/el.json';
import { locale, loadMessages } from 'devextreme/localization';

type AppProps = {
  pca: IPublicClientApplication,
}

function App({ pca }: AppProps) {
  loadMessages(elMessages);
  locale('el');

  return (
    <MsalProvider instance={pca}>
      <Router>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" index element={<WelcomeScreen />}></Route>
          <Route path="/*" element={<LoggedIn />}></Route>
          <Route path="/create" element={<IssueCreation />}></Route>
          <Route path="/list" element={<IssueList />}></Route>
          <Route path="/issue/:key" element={<Issue />}></Route>
        </Routes>
      </Router>
    </MsalProvider >
  );
}

export default App;
