import { Card, CardBody, CardHeader, Container } from "react-bootstrap";

export const WelcomeScreen = () => {
    return (<>
        <Container className="text-center my-5" style={{ width: '30%', marginLeft: '35%', marginRight: '35%' }} >
            <Card>
                <CardHeader className="fs-4">
                    <Card.Text>Welcome!</Card.Text>
                </CardHeader>
                <CardBody className="fs-5">
                    <Card.Text className="my-4 mx-3">Please log in, at the upper-right corner of the screen to use the app.</Card.Text>
                </CardBody>
            </Card>
        </Container>
    </>);
}