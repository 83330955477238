import CustomStore from 'devextreme/data/custom_store';
import * as types from './types'
import { addIssueComment, getIssueComments, getList } from './minoas-api';
import { AuthenticationResult } from '@azure/msal-browser';
import { ToastType } from 'devextreme/ui/toast';
import { Issue } from '../pages/Issue';

export function getDescriptionComments(desc: string): types.Description[] {
    let returnDesc: types.Description[] = [];
    if (desc !== null && desc !== undefined && desc.indexOf(',', 0) > 0) {
        let rows = desc.split('\n');
        rows.forEach(row => {
            let temp = row.split(',')
            let tempDesc: types.Description = {
                commentData: temp[0],
                applicationComment: temp[1],
            }

            returnDesc.push(tempDesc)
        });
    }
    return returnDesc;
}

export function displayPriority(priority: string) {
    switch (priority) {
        case "Highest": return "Πολύ Υψηλή"
        case "High": return "Υψηλή"
        case "Medium": return "Κανονική"
        case "Low": return "Χαμηλή"
        case "Lowest": return "Πολύ Χαμηλή"
    }
}

export const getIssueListDataStore = (filters: types.FilterFields, setToast: React.Dispatch<React.SetStateAction<{ isVisible: boolean, textMessage: string, type: ToastType }>>, auth: AuthenticationResult | null) => new CustomStore({
    loadMode: "processed",
    key: "key",
    load: async (l) => {
        try {
            if (!auth) return;
            let listData = await getList(filters, auth.accessToken)
            return listData;
        }
        catch (err) {
            setToast({ isVisible: true, textMessage: "Error getting Issue List", type: "error" })
            return [];
        }

    }

})


export const getCommentDataStore = (auth: AuthenticationResult | null, setToastConfig: React.Dispatch<React.SetStateAction<{ isVisible: boolean, textMessage: string, type: ToastType }>>, issueKey?: string) => new CustomStore({
    loadMode: "processed",
    key: "key",
    load: async (o) => {
        try {
            if (!auth || !auth.accessToken || !issueKey) return;
            let comments = await getIssueComments(auth.accessToken, issueKey);
            return comments;
        }
        catch (err) {
            setToastConfig({ isVisible: true, textMessage: "There was an error while fetching the comment data.", type: "error" })
            return [];
        }
    },
    insert: async (value) => {
        if (!auth || !auth.accessToken) { return; }

        const res = await addIssueComment(auth.accessToken, issueKey, value);
        if (res.ok) {
            setToastConfig({ isVisible: true, textMessage: "Successfully added new comment.", type: "success" })
        } else {
            setToastConfig({ isVisible: true, textMessage: "Error adding new comment.", type: "error" })
        }
    }
});

