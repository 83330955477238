import { DataGrid, DateBox, Toast } from "devextreme-react";
import { Column, Editing, FormItem, Paging } from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import { IssueForCreation } from "../lib/types";
import { Button, Form } from "react-bootstrap";
import { addAttachment, createIssue } from "../lib/minoas-api"
import { ToastType } from "devextreme/ui/toast_types";
import { FormDropdown, FormInput, FormLabel } from "../components/Form";
import { useAuth } from "../hooks/useAuth";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../lib/msal-config";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../components/AuthGuard";

const today = new Date();
const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
const maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)

export const IssueCreation = () => {
    const [newIssue, setNewIssue] = useState<IssueForCreation>({ description: [], type: "Task" });
    const [toastConfig, setToastConfig] = useState({ isVisible: false, textMessage: "", type: 'success' as ToastType });
    const [attachments, setAttachments] = useState<FileList>();
    const auth = useAuth(loginRequest);
    const { instance, accounts } = useMsal();
    const navigate = useNavigate();

    useEffect(() => { setNewIssue({ description: [], type: "Task", mellonExternalRef: instance.getActiveAccount()?.username, dueDate: new Date() }); }, [instance])

    function submitIssue() {
        if (!auth || !auth.accessToken) { setToastConfig({ isVisible: true, textMessage: "You don't have this permission", type: "error" }); return; }

        createIssue(auth.accessToken, newIssue)
            .then(async r => {
                if (r.ok) {
                    const key = await r.json();

                    if (attachments && attachments?.length > 0) {
                        await addAttachment(auth.accessToken, key.key, attachments);
                    }

                    setToastConfig({ isVisible: true, textMessage: 'Successfully created ticket', type: 'success' });
                    navigate(`/issue/${key.key}`)
                } else {
                    setToastConfig({ isVisible: true, textMessage: 'There was an error while trying to create new ticket', type: 'error' })
                }
                setNewIssue({});
            })
            .catch(e => {
                console.warn(e);
                setToastConfig({ isVisible: true, textMessage: 'An exception was thrown while trying to create new ticket', type: 'error' })
                setNewIssue({});
            });
    }

    return (<AuthGuard>
        <Toast visible={toastConfig.isVisible} message={toastConfig.textMessage} type={toastConfig.type} onHiding={() => setToastConfig({ ...toastConfig, isVisible: false })} />
        <Form onSubmit={(e) => { newIssue.summary = `${newIssue.requestCategory}-${newIssue.applicantSurname}`; e.preventDefault(); e.stopPropagation(); submitIssue(); }}>
            <div className="mb-3" style={{ margin: 30 }}><div className="fs-4">Γενικά στοιχεία αιτήματος</div>
                <div style={{ margin: 20 }}>
                    <div className="row g-3">
                        <FormLabel id="refNo" label="Αρ. Αναφοράς" value="" />
                        <FormLabel id="formType" label="Τμήμα" value={newIssue.label} />
                        <FormLabel id="stage" label="Βήμα:" value="" />
                        <FormLabel id="createdDate" label="Ημ. Δημιουργίας" value={today.toLocaleDateString('el')} />
                        <FormLabel id="reporter" label="Δημιουργήθηκε Από" value="" />
                        <FormLabel id="AssignedTo" label="Ανατέθηκε στον χρήστη" />
                        <FormDropdown required={true} id="inputCategory" value={newIssue.label} divClassName="col-md-4" label="Επιλογή Τμήματος" values={["PRINT", "MAIL"]} onValueChanged={(e) => setNewIssue({ ...newIssue, label: e.target.value })} />
                        <FormDropdown required={true} id="inputSubcategory" divClassName="col-md-4" label="Υποκατηγορίες" values={["Embossing Καρτών", "Embossing PIN"]} onValueChanged={(e) => setNewIssue({ ...newIssue, requestCategory: e.target.value })} />
                        <FormDropdown required={true} id="inputPriority" divClassName="col-md-4" label="Προτεραιότητα" values={["Highest", "High", "Medium", "Low", "Lowest"]} onValueChanged={(e) => setNewIssue({ ...newIssue, priority: e.target.value })}
                            displayValues={["Πολύ Υψηλή", "Υψηλή", "Κανονική", "Χαμηλή", "Πολύ Χαμηλή"]} />
                    </div>
                </div>
                <div style={{ marginTop: 30 }}>Σημειώσεις Αίτησης
                    <div>
                        <DataGrid showBorders={true} dataSource={newIssue.description} onInitNewRow={(e) => { e.data.commentData = accounts[0].name; }}  >
                            <Paging enabled={false} />
                            <Editing mode="form" allowAdding allowUpdating allowDeleting />
                            <Column width={200} dataField="commentData" caption="Πληροφορίες Σημειώσεων" allowEditing={false}></Column>
                            <Column dataField="applicationComment" caption="Σημειώσεις αίτησης"></Column>
                            <FormItem colSpan={2} editorType="dxTextArea" editorOptions={{ height: 100 }} />
                        </DataGrid>
                    </div>
                </div>
                <div style={{ marginTop: 30 }}> Στοιχεία Εκτυπωτικού
                    <div style={{ margin: 20 }}>
                        <div className="row g-3">
                            <FormDropdown required={true} id="inputEmbossingMenu" label="Μενού Εκτυπωτιού" values={["ACBDEB", "ACBDYN", "ACBST", "AMEX", "CRED", "CREDC", "DINERS"]} onValueChanged={(e) => setNewIssue({ ...newIssue, embossingMenu: e.target.value })} />
                            <FormDropdown required={true} id="inputDeliveryType" label="Τύπος Παράδοσης" values={["Κάρτα", "PIN"]} onValueChanged={(e) => setNewIssue({ ...newIssue, deliveryType: e.target.value })} />
                            <FormDropdown required={true} id="inputUsedId" label="Εφαρμογή Σε" values={["Κύρια Κάρτα", "Κύρια & Πρόσθετη", "Κύρια & Πρόσθετη 1 & 2", "Πρόσθετη", "Πρόσθετη 1 & 2"]} onValueChanged={(e) => setNewIssue({ ...newIssue, usedIn: e.target.value })} />
                            <div className="col-md-3">
                                <label htmlFor="inputEmbossDate" className="form-label">Ημερομηνία Emboss</label>
                                <DateBox id="inputEmbossDate" className="form-date form-control" type="date" useMaskBehavior={true} pickerType="calendar" defaultValue={new Date()}
                                    min={minDate} max={maxDate} onValueChange={(e: Date) => { { setNewIssue({ ...newIssue, dueDate: e }); if (e == null) { setToastConfig({ isVisible: true, textMessage: "Enter Valid Embossing Date!", type: "warning" }) } } }}></DateBox>
                            </div>
                            <FormInput id="inputProduct" label="Προϊόν" required={true} onValueChanged={(e) => setNewIssue({ ...newIssue, productType: e.target.value })} />
                            <FormInput id="inputCardRefNo" label="Αρ. Αναφοράς Κάρτας" required={true} onValueChanged={(e) => setNewIssue({ ...newIssue, cardReferenceNo: e.target.value })} />
                            <FormDropdown required={true} id="inputSendFrom" label="Αποστολή Από" values={['Διεύθυνση Billing "L"', "Διεύθυνση Καταστήματος", "Διεύθυνση Courier", 'Διεύθυνση Έκδοσης "Ι"', "Διεύθυνση Κατοικίας", "Διεύθυνση Εργασίας"]
                            } onValueChanged={(e) => setNewIssue({ ...newIssue, sendFrom: e.target.value })} />
                            <FormDropdown required={true} id="inputSendTo" label="Αποστολή Προς" values={['Διεύθυνση Billing "L"', "Διεύθυνση Καταστήματος", "Διεύθυνση Courier", 'Διεύθυνση Έκδοσης "Ι"', "Διεύθυνση Κατοικίας", "Διεύθυνση Εργασίας", "Άλλη Διεύθυνση"]
                            } onValueChanged={(e) => setNewIssue({ ...newIssue, sendTo: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 30 }}> Στοιχεία Αιτούντος
                    <div style={{ margin: 20 }}>
                        <div className="row g-3">
                            <FormInput id="inputSurname" label="Επώνυμο" required={true} onValueChanged={(e) => setNewIssue({ ...newIssue, applicantSurname: e.target.value })} />
                            <FormInput id="inputFirstname" label="Όνομα" required={true} onValueChanged={(e) => setNewIssue({ ...newIssue, applicantFirstname: e.target.value })} />
                            <FormInput id="inputPhoneNo" label="Τηλέφωνο" required={false} onValueChanged={(e) => setNewIssue({ ...newIssue, applicantPhoneNo: e.target.value })} />
                            <FormInput id="inputFaxNo" label="Κινητό" required={true} onValueChanged={(e) => setNewIssue({ ...newIssue, applicantMobilePhoneNo: e.target.value })} />
                            <FormInput id="inputAbroadPhone" label="Τηλέφωνο Εξωτερικού" required={false} onValueChanged={(e) => setNewIssue({ ...newIssue, applicantAbroadPhoneNo: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div>
                    <Form.Label htmlFor="attachment" className="form-label">Add Attachment(s)</Form.Label>
                    <Form.Control type="file" name="attachment" multiple className="form-control dropzone" id="attachment" onChange={(e: any) => setAttachments(e.target.files)} />
                </div >
                <div style={{ marginTop: 30 }}>
                    <Button type="submit" disabled={newIssue.dueDate == null || newIssue.dueDate < minDate || newIssue.dueDate > maxDate}>Υποβολή</Button>
                </div>

            </div >
        </Form >

    </AuthGuard>
    )
}