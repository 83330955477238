import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Button, Container, Nav } from "react-bootstrap";
import NavBar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import { loginRequest } from "../lib/msal-config";
import { useAuth } from "../hooks/useAuth";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export const Navbar = () => {
    const { instance, inProgress, accounts } = useMsal();
    const [userName, setUsername] = useState<string | null>(null);

    useEffect(() => {
        if (accounts.length > 0) {
            setUsername(accounts[0].username);
        }
    }, [accounts]);

    let today = new Date().toLocaleDateString();
    const navigate = useNavigate();

    return (
        <>
            <NavBar bg="light" sticky="top">
                <Container fluid>
                    <Link to="/welcome" className="navbar-brand"><span className="fs-3">Minoas Project</span></Link>
                    <UnauthenticatedTemplate>
                        <Button type="button" onClick={() => { instance.loginPopup(loginRequest).then(() => navigate('/*',)).catch(error => console.error(error)); }}>Log In</Button>
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        <NavBar.Toggle />
                        <NavBar.Collapse>
                            <Nav>
                                <LinkContainer to="/list"><Nav.Link>List</Nav.Link></LinkContainer>
                                <LinkContainer to="/create"><Nav.Link>Create</Nav.Link></LinkContainer>
                            </Nav>
                        </NavBar.Collapse>
                        <NavBar.Collapse className="justify-content-end">
                            {userName && <NavBar.Text>
                                Signed in as: {userName}
                            </NavBar.Text>}
                            <Button className="mx-2" type="button" onClick={() => { navigate(''); instance.logoutPopup().catch(error => console.error(error)); }}>Log Out</Button>
                        </NavBar.Collapse>
                    </AuthenticatedTemplate>
                </Container>
            </NavBar>
        </>
    );
}