import { AccountInfo, AuthenticationResult, IPublicClientApplication, InteractionRequiredAuthError, InteractionStatus, PopupRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

async function login(instance: IPublicClientApplication, request: PopupRequest, inProgress: InteractionStatus) {
    if (inProgress === InteractionStatus.None) {
        const account = instance.getActiveAccount();
        if (!account) {
            try {
                const result = await instance.acquireTokenPopup(request);
                return result;
            }
            catch (e) {
                console.error(e);
            }
        } else {
            try {
                const result = await instance.acquireTokenSilent({
                    ...request,
                    account,
                });
                return result;
            } catch (e) {
                console.error(e);
                if (e instanceof InteractionRequiredAuthError) {
                    const result = await instance.acquireTokenPopup({
                        ...request,
                        account: instance.getActiveAccount() as AccountInfo
                    });
                    return result;
                }
            }
        }
    }
}

export const useAuth = (loginRequest: PopupRequest) => {
    const { instance, inProgress, accounts } = useMsal();
    const [token, setToken] = useState<null | AuthenticationResult>(null)

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            login(instance, loginRequest, inProgress).then(r => setToken(r ?? null));
        }
    }, [inProgress, instance, accounts, loginRequest]);

    return token;
}