import { createRef, useEffect, useRef, useState } from "react";
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Form } from "react-bootstrap"
import { useParams } from "react-router-dom"
import * as types from "../lib/types";
import { addIssueComment, changeStatus, getAttachment, getIssueComments, getIssueDetails } from "../lib/minoas-api";
import { Toast } from "devextreme-react";
import { FormLabel, FormInput } from "../components/Form";
import { ToastType } from "devextreme/ui/toast_types";
import { confirm } from 'devextreme/ui/dialog';
import { useAuth } from "../hooks/useAuth";
import { loginRequest } from "../lib/msal-config";
import { useMsal } from "@azure/msal-react";
import CustomStore from "devextreme/data/custom_store";
import 'devextreme-react/text-area';
import DataGrid, * as DxDataGrid from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { Item } from 'devextreme-react/form';
import { displayPriority, getDescriptionComments, getCommentDataStore } from '../lib/utils'
import { AuthGuard } from "../components/AuthGuard";


export const Issue = () => {
    const [issue, setIssue] = useState<types.IssueDetails>();
    const { key } = useParams();
    const [attachments, setAttachments] = useState<types.IssueAttachment[]>([]);
    const [commentdataStore, setCommentDataStore] = useState<CustomStore>()
    const commentDataGrid = useRef<DataGrid<any, any>>(null);
    const [toastConfig, setToastConfig] = useState({ isVisible: false, textMessage: "", type: 'success' as ToastType });
    const { accounts } = useMsal();
    const auth = useAuth(loginRequest);
    const [userName, setUsername] = useState<string | undefined>();


    const getAttachmentAsync = async (issueKey: string, attachmentId: string, attachmentName: string) => {
        if (!auth) return;
        const result = await getAttachment(auth.accessToken, issueKey, attachmentId);

        if (result.ok) {
            const blob = await result.blob();
            const blobUrl = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobUrl;

            link.download = attachmentName;

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
        }
    }


    const getIssue = async () => {
        try {
            if (!auth || !auth.accessToken) { return; }
            if (key === undefined) { return; }
            const result = await getIssueDetails(auth.accessToken, key);
            setIssue(result);

            if (accounts.length > 0) {
                setUsername(accounts[0].username)
            }

            if (key !== undefined && key !== null) {
                const result = await getAttachment(auth.accessToken, key, "")
                if (result.ok) {
                    const returnObj = await result.json() as types.IssueAttachment[];
                    setAttachments(returnObj)
                }
            }
        }
        catch (error) {
            setToastConfig({ isVisible: true, textMessage: "Error Fetching data: ", type: "error" });
        }
    };
    useEffect(() => { getIssue(); }, [auth || toastConfig.type !== "error"])
    useEffect(() => { setCommentDataStore(getCommentDataStore(auth, setToastConfig, issue?.key)) }, [auth, issue])

    return (
        <AuthGuard>
            <Toast visible={toastConfig.isVisible} message={toastConfig.textMessage} type={toastConfig.type} onHiding={() => setToastConfig({ ...toastConfig, isVisible: false })} />
            {issue ? (

                <Form onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                    <div className="mb-3" style={{ margin: 30 }}><div className="fs-4">Γενικά στοιχεία αιτήματος</div>
                        <div style={{ margin: 20 }}>
                            <div className="row g-3">
                                <FormLabel id="refNo" label="Αρ. Αναφοράς" value={issue.key} />
                                <FormLabel id="formType" label="Τμήμα" value={issue.label} />
                                <FormLabel id="stage" label="Βήμα:" value={issue.status} />
                                <FormLabel id="createdDate" label="Ημ. Δημιουργίας" value={new Date(issue.created).toLocaleDateString('el')} />
                                <FormLabel id="reporter" label="Δημιουργήθηκε Από" value={issue.reporter} />
                                <FormLabel id="AssignedTo" label="Ανατέθηκε στον χρήστη" value={issue.assignee} ></FormLabel>
                                {/* <FormLabel id="inputCategory" label="Κατηγορίες" value={issue.label} /> */}
                                <FormLabel id="inputSubcategory" label="Υποκατηγορίες" value={issue.requestCategory} />
                                <FormLabel id="inputPriority" label="Προτεραιότητα" value={displayPriority(issue.priority)} />
                            </div>
                        </div>
                        <div className="row g-1">
                            <div style={{ marginTop: 30 }} className="col-md-6">Σημειώσεις Αίτησης
                                <div style={{ marginTop: 20 }}>
                                    <DataGrid showBorders={true} dataSource={getDescriptionComments(issue.description)}  >
                                        <DxDataGrid.Paging enabled={false} />
                                        <DxDataGrid.Editing mode="form" />
                                        <DxDataGrid.Column width={200} dataField="commentData" caption="Πληροφορίες Σημειώσεων"></DxDataGrid.Column>
                                        <DxDataGrid.Column width={600} dataField="applicationComment" caption="Σημειώσεις αίτησης"></DxDataGrid.Column>
                                    </DataGrid>
                                </div>
                            </div>
                            <div style={{ marginTop: 70 }} className="col-md-5">
                                <Card >
                                    <CardHeader>Attachments</CardHeader>
                                    <CardBody style={{ height: '80px' }} >
                                        {Array.from(attachments).map((attachment, index) => (
                                            <Badge bg="light">
                                                <a className="fs-6" href='#' onClick={async (e) => { e.preventDefault(); await getAttachmentAsync(issue.key, attachment.key, attachment.fileName); }} key={index}>{attachment.fileName}</a>
                                            </Badge>))}
                                    </CardBody>
                                    <CardFooter></CardFooter>
                                </Card>
                            </div>
                        </div>
                        <div style={{ marginTop: 30 }}> Στοιχεία Εκτυπωτικού
                            <div style={{ margin: 20 }}>
                                <div className="row g-3">
                                    <FormInput id="inputEmbossingMenu" disabled={true} label="Μενού Εκτυπωτιού" required={false} defaultValue={issue.embossingMenu} />
                                    <FormInput id="inputDeliveryType" disabled={true} label="Τύπος Παράδοσης" required={false} defaultValue={issue.deliveryType} />
                                    <FormInput id="inputUsedId" disabled={true} label="Εφαρμογή Σε" required={false} defaultValue={issue.usedIn} />
                                    <div className="col-md-3">
                                        <label htmlFor="inputEmbossDate" className="form-label">Ημερομηνία Emboss</label>
                                        <input id="inputEmbossDate" type="text" disabled={true} className="form-control" defaultValue={issue.dueDate ? new Date(issue.dueDate).toLocaleDateString('el') : ""} readOnly></input>
                                    </div>
                                    <FormInput id="inputProduct" disabled={true} label="Προϊόν" required={false} defaultValue={issue.productType} />
                                    <FormInput id="inputCardRefNo" disabled={true} label="Αρ. Αναφοράς Κάρτας" required={false} defaultValue={issue.cardReferenceNo} />
                                    <FormInput id="inputSendFrom" disabled={true} label="Αποστολή Από" required={false} defaultValue={issue.sendFrom} />
                                    <FormInput id="inputSendTo" disabled={true} label="Αποστολή Προς" required={false} defaultValue={issue.sendTo} />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 30 }}> Στοιχεία Αιτούντος
                            <div style={{ margin: 20 }}>
                                <div className="row g-3">
                                    <FormInput disabled={true} id="inputSurname" label="Επώνυμο" required={false} defaultValue={issue.surname} />
                                    <FormInput disabled={true} id="inputFirstname" label="Όνομα" required={false} defaultValue={issue.firstname} />
                                    <FormInput disabled={true} id="inputPhoneNo" label="Τηλέφωνο" required={false} defaultValue={issue.phoneNo} />
                                    <FormInput disabled={true} id="inputMobileNo" label="Κινητό" required={false} defaultValue={issue.mobilePhoneNo} />
                                    <FormInput disabled={true} id="inputAbroadPhone" label="Τηλέφωνο Εξωτερικού" required={false} defaultValue={issue.abroadPhoneNo} />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 30 }}> Comments
                            <div style={{ margin: 20 }}>

                                <DataGrid
                                    columnAutoWidth={true}
                                    ref={commentDataGrid}
                                    showBorders={true}
                                    dataSource={commentdataStore}
                                    onInitNewRow={(e) => { e.data.reporter = userName; e.data.created = new Date(); }}
                                    onRowUpdating={(opt) => {
                                        opt.newData = { ...opt.oldData, ...opt.newData };
                                    }}
                                    onEditorPreparing={(e) => {
                                        if (e.dataField !== 'description') return;

                                        if (e.row?.isNewRow && e.row.rowType === 'data') {
                                            e.editorOptions.readOnly = false;
                                        } else {
                                            e.editorOptions.readOnly = true;
                                        }
                                    }}
                                >
                                    <DxDataGrid.Editing mode="popup" allowUpdating={false} allowAdding={true} useIcons={true} >
                                        <DxDataGrid.Popup title="Add Comment" showTitle={true} />
                                        <DxDataGrid.Form>
                                            <Item dataField="description" editorType="dxTextArea" colSpan={2} editorOptions={{ height: 500 }} />
                                        </DxDataGrid.Form>
                                    </DxDataGrid.Editing>

                                    <DxDataGrid.Column width={200} dataField="reporter" caption="Δημιουργός" cssClass="vcell" allowEditing={false}></DxDataGrid.Column>
                                    <DxDataGrid.Column dataField="description" caption="Περιγραφή" encodeHtml={false} cssClass="vcell" allowEditing={true}></DxDataGrid.Column>
                                    <DxDataGrid.Column width={200} dataType="date" dataField="created" caption="Ημερομηνία Δημιουργίας" cssClass="vcell" sortOrder="asc" format="dd/MM/yyyy HH:mm" allowEditing={false}></DxDataGrid.Column>
                                </DataGrid>

                            </div>
                        </div>
                    </div >
                </Form >
            ) : (
                <div className="fs-2" style={{ marginLeft: '45%', marginTop: '5%', width: '5%' }}>Loading...</div>
            )
            }
        </AuthGuard>
    );
}

