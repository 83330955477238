import * as types from "./types";

export async function createIssue(token: string, issueForCreation: types.IssueForCreation) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/issue`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify(issueForCreation),
    })

}

export async function addAttachment(token: string, issueKey: string, files?: FileList) {

    if (files !== null && files !== undefined) {

        let toSend = new FormData();

        for (let index = 0; index < files.length; index++) {
            toSend.append(`file[${index + 1}]`, files[index])
        }

        return fetch(`${process.env.REACT_APP_API_URL}/api/issue/${issueKey}/attachment`, {
            method: "POST",
            body: toSend,
            headers: { Authorization: `Bearer ${token}` }
        })
    }
    else {
        return Promise.reject(new Error("No files provided"));
    }
}

export async function getAttachment(token: string, issueKey: string, attachmentId?: string) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/issue/${issueKey}/attachment/${attachmentId}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` }
    })

}

export async function changeStatus(issueKey: string, status: string, token: string) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/issue/${issueKey}/status/${status}`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` }
    })
}

export async function getList(filter: types.FilterFields, token: string) {
    const queryParams = new URLSearchParams()

    Object.entries(filter).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {

            if (value instanceof Date) {
                queryParams.append(key, value.toISOString());
            } else {
                queryParams.append(key, String(value));
            }
        }
    });

    return fetch(`${process.env.REACT_APP_API_URL}/api/issues?${queryParams}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` }
    }).then(r => {
        if (r.ok) {
            return r.json() as Promise<types.IssueList[]>;
        }
    });
}


export async function getIssueDetails(token: string, key: string) {

    return fetch(`${process.env.REACT_APP_API_URL}/api/issue/${key}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` }
    }).then(r => {
        if (r.ok) {
            return r.json() as Promise<types.IssueDetails>;
        } else {
            return undefined;
        }
    });
}

export async function getIssueComments(token: string, issueKey?: string) {
    const returnrObj = await fetch(`${process.env.REACT_APP_API_URL}/api/issue/${issueKey}/comments`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` }
    })

    return await returnrObj.json() as types.IssueComment[];
}

export async function addIssueComment(token: string, issueKey?: string, newComment?: types.IssueComment) {

    const formData = new FormData();
    formData.append("values", JSON.stringify(newComment))

    return await fetch(`${process.env.REACT_APP_API_URL}/api/issue/${issueKey}/comments`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData
    })
}

export async function changeIssueAssignee(token: string, issueKey?: string, assignedTo?: string | null) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api/issue/${issueKey}/assignee/${assignedTo}`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` }
    })
}